<template>
  <b-modal :visible="show" :hide-header="true" centered id="modal-falha-checkout" size="lg" :hide-footer="true">
    <div class="float-right"><img @click="hide" class="close" src="img/x-icon.svg" /></div>
    <div class="row text-center">
      <div class="col-sm-12 col-12 mt-2 pr-0 pl-0">
        <img class="vw-20 vw-m-60 pt-3 pb-3" src="/img/fatura-cancelada.svg" />

        <h4 class="title-modal-falha mb-4 title-bold">Não conseguimos realizar a cobrança!</h4>
        <h5 class="sub-title-modal-falha mb-4">Tivemos um problema na hora que tentamos realizar a cobrança. Por favor,
          verifique
          se os dados estão corretos ou tente colocar outro cartão.</h5>

        <a class="desktop btn ghost mr-3" target="_blank"
          :href="`https://api.whatsapp.com/send?phone=${config.WHATSAPP_NUMERO}&text=Olá, preciso de ajuda no pagamento do meu seguro.`">
          <span class="text">Falar com um atendente</span>
        </a>

        <button class="desktop btn confirm" @click="hide">
          <span class="text">Tentar outro cartão</span>
        </button>

        <a class="mobile btn ghost mb-3" target="_blank"
          :href="`https://api.whatsapp.com/send?phone=${config.WHATSAPP_NUMERO}&text=Olá, preciso de ajuda no pagamento do meu seguro.`">
          <span class="text">Falar com um atendente</span>
        </a>

        <button class="mobile btn confirm mb-3" @click="hide">
          <span class="text">Tentar outro cartão</span>
        </button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import config from "../../config";
import formatacaoMoeda2 from "../../helpers/formatacaoMoeda2";
export default {
  props: ["show"],
  data() {
    return { config, formatacaoMoeda2 };
  },
  methods: {
    hide() {
      this.$emit("hide");
    },
  },
};
</script>

<style>
#modal-falha-checkout___BV_modal_content_ {
  border-radius: 12px !important;
  padding: 18px;
  padding-top: 15px;
}

.ghost {
  font-weight: 600;
  text-decoration: none;
  border: 1px solid;
  border-radius: 7px;
  padding: 15px;
}

.title-bold {
  font-weight: 800;
}

.close {
  width: 15px;
  cursor: pointer;
}

.vw-20 {
  width: 20vw;
}

.voltar {
  color: #9a9a9a;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.confirm {
  font-size: 18px;
  padding: 12px 20px 12px 20px;
  border-radius: 7px;
  border: 1px solid #4FA935;
  background: #4FA935;
  color: #fff;
  font-weight: 600;
}

.title-modal-falha {
  color: #425994;
}

.sub-title-modal-falha {
  color: #78849E;
}


@media (max-width: 768px) {

  .title-modal-falha {
    font-size: 20px !important;
    font-weight: bold;
  }

  .sub-title-modal-falha {
    font-size: 14px !important;
  }

  .sub-title {
    font-size: 17px;
  }

  .title {
    font-size: 20px;
  }

  .vw-m-60 {
    width: 60vw;
  }

  #modal-falha-checkout___BV_modal_content_ {
    padding: 0 !important;
    padding-top: 0 !important;
  }

  .desktop {
    display: none !important
  }
}

@media (min-width: 769px) {
  .mobile {
    display: none !important
  }
}
</style>
